import axios from 'axios';

import { API_ORIGIN, BASE_API_URL, BASE_PAYMENT_DETAILS_API } from './index';

export const authorizedReq = axios.create();

export const getPublicApiKey = () =>
  authorizedReq({
    url: `${BASE_API_URL}/auth/public-api-key`,
  });

export const checkoutConfig = (session_id, headers) =>
  authorizedReq({
    method: 'get',
    url: `${API_ORIGIN}/checkout/api/intl/v1/checkout-page/${session_id}`,
    headers,
  });

export const getPaymentAttemptDetails = (reference_number, headers) =>
  axios.get(
    `${BASE_PAYMENT_DETAILS_API}/attempt-details/${reference_number}/`,
    {
      headers,
    },
  );

export const getPaymentAttemptStatus = (encryptedID, headers) =>
  axios.get(
    `${API_ORIGIN}/checkout/api/intl/v1/awaiting-page/${encryptedID}/`,
    {
      headers,
    },
  );

export const getDefaultUnitDetails = (headers) =>
  axios.get(`${BASE_API_URL}/unit/default`, { headers });
